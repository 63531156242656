import { registerApplication, start, navigateToUrl } from "single-spa";
import { createBrowserHistory } from "history";
import { createConfig, createLogger } from "@sage/sbc.core.utils.ui";
import {
  configure as configureScene,
  cleanup as cleanupScene,
} from "@sage/sbc.core.scene.ui";
import { QUERY_PATTERN, Constants } from "./constants";
import { clearDbError, deleteIndexDbKeys } from "./utils/deleteIndexDbKeys";
import "@angular/localize/init";

const { config } = createConfig();
const logger = createLogger();
const history = createBrowserHistory();
const sfaUserKeyName = "isSFAUser";

const pathname = (str: string) => (location: Location) => {
  return location.pathname.startsWith(str);
};
const rootPath = () => (location: Location) => location.pathname === "/";

const props = {
  history,
  domElementGetter: () => {
    return document.getElementById("app");
  },
};

function checkIsSfaUserFromQueryParam(name: string): boolean {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name) === "true";
}

function getSubFromQueryParam(): string {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("sub");
}

function checkIsSfaUserFromLocalStorage(name: string): boolean {
  return localStorage.getItem(name) === "true";
}

function getIsPracticeClientFromQueryParam() : string | undefined {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("isPracticeClient");
}

function checkIsPracticeCompanyFromStorage() : boolean {
  const companyId = Number(sessionStorage.getItem("SFA_User_Company_Id"));

  if (Number.isNaN(companyId) || companyId === 0) return false;
  
  return sessionStorage.getItem("companySFAPractice-" + companyId) === "true";
}

function setAuth0Id(): void {
  let sub = getSubFromQueryParam();
  if (sub) {
    sub = `auth0|${sub}`;
  } else {
    sub = localStorage.getItem("auth0Id");
  }
  window["DATA_AUTH0ID"] = sub;
}

// Check if the user is SFA user from the URL or local storage
function isRegisterGlobalNav(): boolean {
  return (
    checkIsSfaUserFromQueryParam(sfaUserKeyName) ||
    checkIsSfaUserFromLocalStorage(sfaUserKeyName)
  );
}

// check if the product switcher should be hidden conditionally when company is not a practice company
function isProductSwitcherHidden(): boolean {
    const isPracticeCompany = getIsPracticeClientFromQueryParam();
    if (isPracticeCompany != null) {
      return isPracticeCompany.toLocaleLowerCase() !== "true";
    }

    const isPractice = checkIsPracticeCompanyFromStorage();

    return !isPractice;
}

// Fetch import map dynamically
const fetchImportMap = async () => {
  const response = await fetch("/importmap.json");
  if (!response.ok) {
    throw new Error(`Failed to load importmap.json: ${response.statusText}`);
  }
  return response.json();
};

const loadAngularApp = async () => {
  const importmap = await fetchImportMap();
  const angularAppUrl = importmap.imports["angular-autoentry"];
  return System.import(angularAppUrl);
};

registerApplication(
  "angular-autoentry",
  // @ts-ignore
  loadAngularApp,
  () => true,
  {
    ...props,
    domElementGetter: () => {
      return document.getElementById("app");
    },
  }
);

function isPublicPath(location: Location) {
  const publicPaths = [
    "/login",
    "/signup",
    "/setup",
    "/forbidden",
    "/password/reset/",
    "/password/forgot",
    "/password/newrecoverycode/",
    "/public/show-invoice",
    "/notifications/unsubscribe",
    "/access-denied",
    "/practice-access-denied",
    "/sage-sign-in-blocked",
    "/error",
  ];
  return publicPaths.some((path) => location.pathname.startsWith(path));
}

/**
 * Start of data cleanup on fresh login / logout code
 */
const clearUserDataCallback = async () => {
  deleteIndexDbKeys(QUERY_PATTERN);
  // Clear local storage, excluding import map overrides where applicable
  if (config("features.sbc.core.devtools.ui.enabled")) {
    let preserve: Record<string, string> = {};
    Object.keys(localStorage).forEach((key) => {
      if (
        key.startsWith("import-map-override") ||
        [
          Constants.GLOBAL_CONFIG_OVERRIDES,
          Constants.GLOBAL_HANDLER_DEFINITIONS,
          Constants.GLOBAL_PERMISSIONS_OVERRIDES,
          Constants.GLOBAL_FEATURES_OVERRIDES,
          Constants.GLOBAL_MOCK_WORKFLOW_NOTIFICATIONS,
        ].includes(key)
      ) {
        const value = localStorage.getItem(key);
        if (value !== null) {
          preserve[key] = value;
        }
      }
    });

    localStorage.clear();

    Object.keys(preserve).forEach((key) => {
      localStorage.setItem(key, preserve[key]);
    });
  } else {
    localStorage.clear();
  }

  // Clear session storage
  sessionStorage.clear();

  // Clear service worker cache
  const response = new Promise((resolve: (value?: unknown) => void) => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.onmessage = (event) => {
        if (event.data) {
          var message = JSON.parse(event.data);
          if (message.type === "CACHE_INVALIDATED") {
            resolve();
          }
        }
      };
    }
  });

  if ("serviceWorker" in navigator && navigator.serviceWorker.controller) {
    try {
      await navigator.serviceWorker.ready.then((registration) => {
        (registration.active as ServiceWorker).postMessage("INVALIDATE_CACHE");
      });

      await response;
    } catch (error) {
      logger.error(error);
    }
  }
  cleanupScene();
};

function registerGlobalNavApps() {
  registerApplication(
    "sbc.core.globalnav.ui",
    () =>
      System.import("sbc.core.globalnav.ui/index.js").then(
        ({ NavBarLifecycles }) => NavBarLifecycles
      ),
    (location) => !isPublicPath(location),
    {
      ...props,
      domElementGetter: () => {
        return document.getElementById("nav");
      },
      shouldHideProductSwitcher: isProductSwitcherHidden()
    }
  );

  const shouldLoadConsent = (location) =>
    /**
     * When we are on the logout page we do not want to be attempting to pop the consent modal
     */
    !pathname("/logout")(location) &&
    /**
     * When we are on the logged out page we do not want to be attempting to pop the consent modal
     */
    !pathname("/logged-out")(location) &&
    /**
     * We do not want to load consent until the url is one of the products
     */
    !rootPath()(location);

  registerApplication(
    "sbc.core.consent.ui",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    () => System.import("sbc.core.consent.ui/index.js"),
    (location) =>
      shouldLoadConsent(location) &&
      !isPublicPath(location),
    { history }
  );

  window.addEventListener("storage", (event) => {
    if (event.storageArea != localStorage) return;
    if (event.key === "clearUserData") {
      (async () => {
        await clearUserDataCallback();
        if (
          !pathname("/logout")(location) &&
          !pathname("/logged-out")(location)
        ) {
          window.location.reload();
        }
      })();
    }
  });

  clearDbError();

  /**
   * End of data cleanup on logout code
   */

  /**
   * Configure Scene, the micro front end responsible for calling the Post User Login endpoint in Org Service.
   * The response data is then used by all the micro front ends to ensure they all have the context of the logged in user.
   */
  configureScene(history);
}

function activate() {
  if (!isPublicPath(window.location) && isRegisterGlobalNav()) {
    setAuth0Id();
    document.getElementById("nav").style.display = "block";
    registerGlobalNavApps();
  }

  start();
}

//////////////////////////////////
activate();

export {
  checkIsSfaUserFromQueryParam,
  checkIsSfaUserFromLocalStorage,
  setAuth0Id,
  checkIsPracticeCompanyFromStorage,
  isProductSwitcherHidden
};
